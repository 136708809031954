import { BaseTypography, StaticTypographyProps } from "./types";
import classnames from "classnames";

const onDarkClassName = `text-primary`;
const onLightClassName = `text-invert-primary`;

export type LabelProps = BaseTypography &
  React.HtmlHTMLAttributes<HTMLLabelElement>;

const labelClassNames =
  "font-ui text-sm leading-normal tracking-[0.01em] antialiased";
const Label: React.FunctionComponent<LabelProps> & StaticTypographyProps = ({
  children,
  className,
  onDark,
  ...other
}): JSX.Element => {
  return (
    <label
      className={classnames(
        labelClassNames,
        className,
        onDark ? onDarkClassName : onLightClassName,
      )}
      {...other}
    >
      {children}
    </label>
  );
};
Label.classes = labelClassNames;

export { Label };
