"use client";
import { Listbox as HeadlessListbox, Transition } from "@headlessui/react";
import classnames from "classnames";
import { Fragment, ReactElement, ReactNode } from "react";
import { ChevronArrowDown } from "../Icons";
import { Typography } from "../Typography";

export type SelectProps = {
  className?: string;
  options: Array<SelectOption>;
  label: string;
  onDark?: boolean;
  onSelect?: (value: any) => void;
  selected?: any;
  buttonClassNames?: string;
  buttonIcon?: ReactNode;
  uncontrolled?: boolean;
  noOptionsText?: string;
  icon?: ReactNode;
} & Omit<React.HTMLProps<HTMLInputElement>, "selected">;

export type SelectOption = {
  description?: ReactElement | string;
  name: string;
  id: string;
};

const Select: React.FC<SelectProps> = ({
  options,
  as,
  label,
  className,
  onDark,
  onSelect,
  selected,
  buttonClassNames,
  buttonIcon,
  uncontrolled,
  noOptionsText,
  icon,
  ...other
}) => {
  const passDown: any = {};
  if (uncontrolled) {
    passDown.defaultValue = selected;
  } else {
    passDown.value = selected;
  }
  return (
    <div className={classnames(className, "")}>
      <HeadlessListbox {...passDown} {...other} onChange={onSelect}>
        {({ open }) => (
          <>
            <HeadlessListbox.Label
              className={classnames(
                onDark ? "text-primary" : "text-invert-primary",
                "col-start-1 col-end-3 flex items-center",
              )}
            >
              {icon}
              <Typography.Body className="!text-sm font-normal !leading-5">
                {label}
              </Typography.Body>
            </HeadlessListbox.Label>
            <div className={classnames("relative", label && "mt-1")}>
              <HeadlessListbox.Button
                className={classnames(
                  "relative min-h-10 w-full rounded py-2.5 pl-4 pr-12 text-left text-gray-900 hover:shadow-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-invert-primary focus-visible:ring-offset-4 sm:text-sm sm:leading-6",
                  buttonClassNames,
                  {
                    "cursor-pointer": !other.disabled,
                    "cursor-default": other.disabled,
                    "bg-secondary-alt": open,
                    "bg-secondary": !open,
                  },
                )}
              >
                {({ value }) => (
                  <>
                    <span className="flex w-full truncate">
                      <span className="truncate text-base !leading-5">
                        {value?.name}
                      </span>
                      {value?.save && (
                        <span className="ml-3 w-fit rounded bg-[#FBFF3C] px-3 pt-[3px] !text-xs !font-bold">
                          {value?.save}
                        </span>
                      )}
                      <span className="ml-auto">{value?.description}</span>
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center py-4 pr-4">
                      {buttonIcon || (
                        <ChevronArrowDown
                          className=" text-gray-400"
                          aria-hidden="true"
                          height={"12px"}
                          width={"12px"}
                          strokeWidth={4}
                        />
                      )}
                    </span>
                  </>
                )}
              </HeadlessListbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <HeadlessListbox.Options className="custom-scrollbar absolute	 z-10 mt-1 max-h-60 w-full scroll-p-2.5 overflow-auto rounded-md bg-secondary py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus-visible:outline-none sm:text-sm">
                  {options.map((option) => (
                    <HeadlessListbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classnames(
                          "relative cursor-pointer select-none px-4 py-2.5 text-invert-primary",
                          { "bg-secondary-alt": active },
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex gap-2.5">
                            <span
                              className={classnames(
                                selected ? "font-semibold" : "font-normal",
                                "truncate",
                              )}
                            >
                              {option.name}
                            </span>
                            <span
                              className={classnames(
                                active ? "text-indigo-200" : "text-gray-500",
                                "ml-2 flex flex-1 justify-end truncate",
                              )}
                            >
                              {option.description}
                            </span>
                          </div>
                        </>
                      )}
                    </HeadlessListbox.Option>
                  ))}
                  {options.length < 1 && noOptionsText ? (
                    <Typography.Body className="px-3 py-2.5">
                      {noOptionsText}
                    </Typography.Body>
                  ) : null}
                </HeadlessListbox.Options>
              </Transition>
            </div>
          </>
        )}
      </HeadlessListbox>
    </div>
  );
};
export { Select };
