import classnames from "classnames";
import { Loader } from "../Loader";
import { CloseButton, CloseButtonProps } from "./CloseButton";
import React from "react";

const baseClassName = `
group
  disabled:opacity-40
  disabled:pointer-events-none
  ease-in-out
  inline-block
  leading-tight
  rounded 
  shadow-none
  transition duration-400
  font-ui
  items-center
  justify-center
  font-semibold
  tracking-[0.02em]
  antialiased
`;

const classNames = {
  primary: `
    rounded-full

    focus-visible:outline-offset-transparent
    focus-visible:outline-offset-[3px]
    focus-visible:outline-2
    focus-visible:outline


    hover:shadow-lg
    active:shadow-lg    
`,
  secondary: `
  bg-transparent
  border
  rounded-full

  focus-visible:outline-offset-transparent
  focus-visible:outline-offset-[3px]
  focus-visible:outline-2
  focus-visible:outline
`,
  tertiary: `
  bg-transparent
  rounded-lg
  p-[5px]
  border-2
  border-transparent
  focus-visible:outline-none
  `,
  small: "py-1.5 px-3 text-sm",
  medium: "py-2.5 px-5 text-base",
  large: "py-4 px-7 text-base",
  withIcon: "flex justify-center gap-x-2.5",
  disabledClassName: "!opacity-40",
  iconOnlyClassName: `
  flex
  items-center
  justify-center
  !w-12
  !h-12
  px-3
  py-3
  !rounded-full
  relative
  `,
  numberIndicatorClassName: `
  absolute
  left-full
  flex
  h-[18px]
  min-w-[18px]
  translate-x-[-60%]
  items-center
  justify-center
  rounded-full
  bg-brand
  p-1
  text-xs
  `,
  primary_black: `
  bg-invert-primary
  text-primary
  border-none
  [&_*>svg>path]:stroke-[white]
  [&_*>svg>g]:fill-[white]

  hover:bg-invert-primary-alt
  active:bg-invert-primary-alt
  active:border-invert-primary-alt

  focus-visible:bg-invert-primary
  focus-visible:border-invert-primary
  focus-visible:outline-invert-primary

  disabled:invert-primary
  disabled:border-none
  disabled:shadow-none
  `,
  primary_white: `
  bg-primary
  text-invert-primary

  hover:bg-primary-alt
  active:bg-primary-alt
  active:border-primary-alt

  focus-visible:bg-primary
  focus-visible:border-primary
  focus-visible:outline-primary

  disabled:bg-primary
  disabled:border-none
  disabled:shadow-none
  `,
  primary_green: `
  text-invert-primary
  stroke-invert-primary
  bg-brand
  border
  border-brand

  hover:bg-brand-alt
  hover:border-brand-alt

  active:bg-brand-alt
  active:border-brand-alt

  focus-visible:bg-brand-alt
  focus-visible:border-brand-alt
  focus-visible:outline-brand-alt

  disabled:bg-brand
  disabled:border-primary
  disabled:shadow-none
  `,
  primary_red: `
  text-primary
  stroke-primary
  bg-negative
  border
  border-negative
  [&_*>svg>path]:stroke-primary
  [&_*>svg>g]:fill-primary

  hover:bg-negative-alt
  hover:border-negative-alt

  active:bg-negative-alt
  active:border-negative-alt

  focus-visible:bg-negative-alt
  focus-visible:border-negative-alt
  focus-visible:outline-negative-alt

  disabled:bg-negative
  disabled:border-negative
  disabled:shadow-none
  `,
  primary_transparent: "",
  secondary_black: `
  border-invert-primary
  text-invert-primary

  hover:bg-invert-primary
  hover:text-primary

  focus-visible:outline-invert-primary
  focus-visible:bg-invert-primary
  focus-visible:text-primary

  disabled:invert-primary
  disabled:shadow-none
  disabled:bg-transparent
  disabled:text-neutral-900

  [&_*>svg>path]:hover:stroke-primary
  [&_*>svg>g]:hover:fill-primary

  [&_*>svg>path]:focus-visible:stroke-primary
  [&_*>svg>g]:focus-visible:fill-primary
  `,
  secondary_red: `
  border-negative
  text-negative
  [&_*>svg>path]:stroke-negative
  [&_*>svg>g]:fill-negative

  hover:bg-negative
  hover:text-primary

  focus-visible:outline-negative
  focus-visible:bg-negative
  focus-visible:text-primary

  disabled:invert-primary
  disabled:shadow-none
  disabled:bg-transparent
  disabled:text-negative
  disabled:border-negative

  [&_*>svg>path]:hover:stroke-primary
  [&_*>svg>g]:hover:fill-primary
  [&_*>svg>path]:focus-visible:stroke-primary
  [&_*>svg>g]:focus-visible:fill-primary
  `,
  secondary_white: `
  border-primary
  text-primary

  [&_*>svg>path]:stroke-primary
  [&_*>svg>g]:fill-primary

  disabled:border-primary
  disabled:shadow-none
  disabled:bg-transparent
  disabled:text-primary

  focus-visible:outline-primary
  focus-visible:bg-primary
  focus-visible:text-invert-primary
 
  hover:bg-primary
  hover:text-invert-primary

  [&_*>svg>path]:hover:stroke-invert-primary
  [&_*>svg>g]:hover:fill-invert-primary
  [&_*>svg>path]:focus-visible:stroke-invert-primary
  [&_*>svg>g]:focus-visible:fill-invert-primary
  `,
  tertiary_black: `
  text-invert-primary

  hover:text-invert-primary-alt

  focus-visible:border-invert-primary-alt
  
  [&>span]:border-neutral-900
  [&>span]:focus-visible:border-neutral-900
  `,
  tertiary_white: `
  text-primary

  focus-visible:outline-0
  focus-visible:border-primary 
  
  [&>span]:border-primary
  [&_*>svg>path]:stroke-[white]
  [&_*>svg>g]:fill-[white]
  [&>span]:focus-visible:border-primary
  `,
  iconOnly_black: `
  !text-neutral-900
  focus:!ring-0
  focus:!ring-offset-0
  
  focus-visible:border-neutral-900 
  focus-visible:outline-offset-transparent
  focus-visible:outline-2
  focus-visible:outline-offset-[3px]
  focus-visible:outline-neutral-900
`,
  iconOnly_white: `
  focus:!ring-0
  focus:!ring-offset-0
  
  focus-visible:outline-offset-transparent
  focus-visible:border-neutral-50 
  focus-visible:outline-2
  focus-visible:outline-offset-[3px]
  focus-visible:outline-neutral-50
  `,
  iconOnly_green: ``,
  iconOnly_transparent: ``,
  secondary_green: ``,
  tertiary_green: ``,
  secondary_transparent: ``,
  tertiary_transparent: ``,
  iconOnly_red: ``,
  tertiary_red: ``,
};

export type ButtonProps = {
  size?: "small" | "medium" | "large";
  variant?: "primary" | "secondary" | "tertiary";
  color: "black" | "white" | "green" | "red" | "transparent";
  iconOnly?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  onDark?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  numberIndicator?: number;
  noBorder?: boolean;
  skipStyles?: boolean;
  loadingText?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement> & {
    classes?: string;
  };

export type ButtonStatic = {
  CloseButton: React.FunctionComponent<CloseButtonProps>;
};

type ButtonComponentType = React.FunctionComponent<ButtonProps> & {
  classes: {
    baseClassName: string;
    classNames: {
      primary: string;
      secondary: string;
      tertiary: string;
      small: string;
      medium: string;
      large: string;
      iconOnlyClassName: string;
      primary_black: string;
      primary_white: string;
      primary_green: string;
      secondary_black: string;
      secondary_white: string;
      secondary_green: string;
      tertiary_black: string;
      tertiary_white: string;
      tertiary_green: string;
      withIcon: string;
    };
  };
};

const Button: ButtonComponentType & ButtonStatic = ({
  size = "medium",
  variant = "primary",
  color,
  children,
  className,
  onDark,
  rightIcon,
  leftIcon,
  loading = false,
  disabled,
  iconOnly,
  numberIndicator,
  noBorder = false,
  skipStyles = false,
  loadingText,
  ...other
}) => (
  <button
    disabled={disabled || loading}
    className={
      skipStyles
        ? className
        : classnames(
            baseClassName,
            classNames[`${variant}_${color}`],
            {
              [classNames[variant]]: color !== "transparent",
              [classNames[size]]: !iconOnly && variant !== "tertiary",
              [classNames.disabledClassName]: disabled,
            },
            (leftIcon || rightIcon) && classNames.withIcon,
            iconOnly && classNames.iconOnlyClassName,
            iconOnly && classNames[`iconOnly_${color}`],
            className,
          )
    }
    {...other}
  >
    {leftIcon && <span className="flex items-center">{leftIcon}</span>}
    {iconOnly && (
      <span>
        {iconOnly}
        {numberIndicator && (
          <span className={classNames.numberIndicatorClassName}>
            {numberIndicator}
          </span>
        )}
      </span>
    )}
    {!iconOnly && variant !== "tertiary" && (
      <>
        {loading ? (
          <div className="flex flex-row items-center justify-center">
            <Loader className="max-h-5" onDark={onDark} />{" "}
            {loadingText || "Loading..."}
          </div>
        ) : (
          children
        )}
      </>
    )}
    {!iconOnly && variant === "tertiary" && (
      <>
        {loading ? (
          <div className="flex flex-row items-center justify-center">
            <Loader className="max-h-5" onDark={onDark} />
            {loadingText || "Loading..."}
          </div>
        ) : noBorder ? (
          children
        ) : (
          <span className="border-b py-1 hover:border-transparent group-focus-visible:border-transparent">
            {children}
          </span>
        )}
      </>
    )}
    {rightIcon && <span className="flex items-center">{rightIcon}</span>}
  </button>
);

Button.classes = {
  baseClassName,
  classNames,
};
Button.CloseButton = CloseButton;

export { Button };
