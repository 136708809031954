import { BaseSvgProps } from "./types";
const SvgArrowCurvedRight = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M1.566 12.002h20.9m0 0s-8.75 0-8.75-10.406m8.75 10.406s-8.75 0-8.75 10.464"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgArrowCurvedRight;
