import { BaseTypography } from "./types";

export const Tag: React.FunctionComponent<BaseTypography> = ({
  children,
  className,
  style,
}): JSX.Element => {
  return (
    <span
      tabIndex={0}
      style={style}
      className={`font-ui text-sm font-normal antialiased ${className}`}
    >
      {children}
    </span>
  );
};
