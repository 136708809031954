import { BaseTypography, StaticTypographyProps } from "./types";

export type ErrorMessageProps = BaseTypography &
  React.HtmlHTMLAttributes<HTMLSpanElement>;

const errorClasses =
  "font-ui text-sm leading-normal tracking-[0.01em] text-negative antialiased";
const ErrorMessage: React.FunctionComponent<ErrorMessageProps> &
  StaticTypographyProps = ({ children, className, ...other }): JSX.Element => {
  return (
    <span className={`${errorClasses} ${className}`} {...other}>
      {children}
    </span>
  );
};
ErrorMessage.classes = errorClasses;

export { ErrorMessage };
