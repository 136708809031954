import React from "react";
import type { BodyProps } from "./Body";
import { Body } from "./Body";
import { ErrorMessage, ErrorMessageProps } from "./ErrorMessage";
import { Heading1, Heading2, Heading3 } from "./Headings";
import { Label, LabelProps } from "./Label";
import { Tag } from "./Tag";
import { BaseTypography, StaticTypographyProps } from "./types";

export type TypographyProps =
  | ({ type?: "body" } & BodyProps)
  | ({ type: "h1" | "h2" | "h3" | "tag" } & BaseTypography);

export type TypographyStatic = {
  Heading1: React.FunctionComponent<BaseTypography> & StaticTypographyProps;
  Heading2: React.FunctionComponent<BaseTypography> & StaticTypographyProps;
  Heading3: React.FunctionComponent<BaseTypography> & StaticTypographyProps;
  Body: React.FunctionComponent<BodyProps>;
  Tag: React.FunctionComponent<BaseTypography>;
  Label: React.FunctionComponent<LabelProps> & StaticTypographyProps;
  ErrorMessage: React.FunctionComponent<ErrorMessageProps> &
    StaticTypographyProps;
};

const Typography: React.FunctionComponent<TypographyProps> &
  TypographyStatic = ({ type = "body", ...other }) => {
  let Component = Body;

  switch (type) {
    case "h1": {
      Component = Heading1;
      break;
    }
    case "h2": {
      Component = Heading2;
      break;
    }
    case "h3": {
      Component = Heading3;
      break;
    }
    case "tag": {
      Component = Tag;
      break;
    }
    default: {
      Component = Body;
      break;
    }
  }
  return <Component {...other} />;
};

Typography.Heading1 = Heading1;
Typography.Heading2 = Heading2;
Typography.Heading3 = Heading3;
Typography.Body = Body;
Typography.Tag = Tag;
Typography.Label = Label;
Typography.ErrorMessage = ErrorMessage;

export { Typography };
