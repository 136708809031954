import { BaseTypography, StaticTypographyProps } from "./types";
import classnames from "classnames";

const onDarkClassName = `text-neutral-50`;
const onLightClassName = `text-neutral-900`;

const baseHeadingClasses = `
font-headings
font-bold
text-5xl 
lg:text-7xl
antialiased`;

const Heading1: React.FunctionComponent<BaseTypography> &
  StaticTypographyProps = ({ children, className, onDark }): JSX.Element => {
  return (
    <h1
      className={classnames(
        baseHeadingClasses,
        className,
        onDark ? onDarkClassName : onLightClassName,
      )}
    >
      {children}
    </h1>
  );
};
Heading1.classes = baseHeadingClasses;

const baseHeading2Classes = `
font-headings
font-bold
text-2xl
lg:text-4xl
antialiased`;

const Heading2: React.FunctionComponent<BaseTypography> &
  StaticTypographyProps = ({ children, className, onDark }): JSX.Element => {
  return (
    <h2
      className={classnames(
        baseHeading2Classes,
        className,
        onDark ? onDarkClassName : onLightClassName,
      )}
    >
      {children}
    </h2>
  );
};
Heading2.classes = baseHeading2Classes;

const baseHeading3Classes = `
font-ui
text-lg
font-bold
antialiased`;

const Heading3: React.FunctionComponent<BaseTypography> &
  StaticTypographyProps = ({ children, className, onDark }): JSX.Element => {
  return (
    <h3
      className={classnames(
        baseHeading3Classes,
        className,
        onDark ? onDarkClassName : onLightClassName,
      )}
    >
      {children}
    </h3>
  );
};
Heading3.classes = baseHeading3Classes;

export { Heading1, Heading2, Heading3 };
