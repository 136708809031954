import classnames from "classnames";
import { Loader } from "../Loader";
import { CloseCrossX } from "../Icons";
import { Typography } from "../Typography";

const baseClassName = `
  group
  disabled:opacity-40
  disabled:pointer-events-none
  ease-in-out
  inline-block
  shadow-none
  transition duration-400
  items-center
  justify-center
  tracking-[0.02em]
  antialiased
  bg-transparent
  rounded-lg
  p-[5px]
  focus-visible:outline-none
  flex
  justify-center
  gap-x-2.5
  
`;

const classNames = {
  black: `
  focus-visible:border-invert-primary-alt
  [&>span]:border-neutral-900
  focus-visible:border-neutral-900
  focus-visible:border-2
  `,
  blackText: `
  text-invert-primary
  hover:text-invert-primary-alt
  `,
  white: `
  focus-visible:border-primary 
  focus-visible:text-primary-alt
  [&>span]:border-primary
  [&_*>svg>path]:stroke-[white]
  [&_*>svg>g]:fill-[white]
  focus-visible:outline-0
  focus-visible:border-primary 
  focus-visible:border-2
  `,
  whiteText: `
  text-primary
  `,
};

export type CloseButtonProps = {
  color: "black" | "white";
  children?: React.ReactNode;
  className?: string;
  onDark?: boolean;
  iconRight?: boolean;
  loading?: boolean;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> & {
    classes?: string;
  };

type ButtonComponentType = React.FunctionComponent<CloseButtonProps> & {
  classes: {
    baseClassName: string;
    classNames: {
      black: string;
      white: string;
    };
  };
};

const CloseButton: ButtonComponentType = ({
  color,
  className,
  onDark,
  loading = false,
  disabled,
  iconRight = false,
  ...other
}) => (
  <button
    disabled={disabled || loading}
    className={classnames(baseClassName, classNames[`${color}`], className)}
    {...other}
  >
    {!iconRight && (
      <span className="flex items-center">
        <CloseCrossX width={16} height={16} strokeWidth={2} />
      </span>
    )}
    {loading ? (
      <div className="flex flex-row items-center justify-center">
        <Loader className="max-h-5" onDark={onDark} /> Loading...
      </div>
    ) : (
      <span className="border-b pb-1 pt-[5px] group-hover:border-transparent group-focus-visible:border-transparent">
        <Typography.Body
          className={classnames(
            "font-ui !font-semibold !leading-5",
            classNames[`${color}Text`],
          )}
        >
          Close
        </Typography.Body>
      </span>
    )}
    {iconRight && (
      <span className="flex items-center">
        <CloseCrossX width={16} height={16} strokeWidth={2} />
      </span>
    )}
  </button>
);

CloseButton.classes = {
  baseClassName,
  classNames,
};

export { CloseButton };
